@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    330deg,
    rgba(255, 255, 255, 1) 69%,
    #38bff84a 100%
  ); */
  background: rgb(252, 254, 255);
  background-repeat: no-repeat;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker-calendar thead th {
  text-align: center;
  padding-inline-end: 0 !important;
  font-weight: 600 !important;
}

.ant-picker-calendar tr {
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-layout-sider-trigger {
  background-color: #38bdf8;
}
